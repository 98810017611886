<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Bottom Action Bar">
                    <template v-slot:description>
                        <div>import { HbBottomActionBar } from 'hummingbird-aviary';</div>
                        <div class="mt-2">Important Notes: This component should be used at all times for the bottom actions of slideout screens and modals as this standardizes the height and spacing of all elements. This has already been baked into the &lt;hb-modal> component so follow the &lt;hb-modal> documentation for usage there. Also, there should only ever be 1 "primary" colored button on the bottom action bar. If you see something different in a design mockup, please reach out to the designers or PMs for clarity.</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Bottom Action Bar Examples + Code" class="mt-4 mb-6">
            <div class="pa-4">
                <hb-bottom-action-bar>
                    <template v-slot:left-actions>
                        <hb-link @click="doSomething()">Link</hb-link>
                    </template>
                    <template v-slot:right-actions>
                        <hb-btn color="secondary" @click="doSomething()">Fifth</hb-btn>
                        <hb-btn color="secondary" @click="doSomething()">Fourth</hb-btn>
                        <hb-btn color="secondary" @click="doSomething()">Third</hb-btn>
                        <hb-btn color="secondary" @click="doSomething()">Second</hb-btn>
                        <hb-btn @click="doSomething()">First</hb-btn>
                    </template>
                </hb-bottom-action-bar>
            </div>

            <div class="pa-4">
                <hb-bottom-action-bar close-instead-of-cancel>
                    <template v-slot:left-actions>
                        <HbBtn icon tooltip="Share" @click="doSomething()" mdi-icon="mdi-user-actions-custom-1" />
                    </template>
                    <template v-slot:right-actions>
                        <hb-btn color="secondary" @click="doSomething()">Third</hb-btn>
                        <hb-btn color="secondary" @click="doSomething()">Second</hb-btn>
                        <hb-btn @click="doSomething()">First</hb-btn>
                    </template>
                </hb-bottom-action-bar>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-bottom-action-bar>
    &lt;template v-slot:left-actions>
        &lt;hb-link @click="doSomething()">Link&lt;/hb-link>
    &lt;/template>
    &lt;template v-slot:right-actions>
        &lt;hb-btn color="secondary" @click="doSomething()">Fifth&lt;/hb-btn>
        &lt;hb-btn color="secondary" @click="doSomething()">Fourth&lt;/hb-btn>
        &lt;hb-btn color="secondary" @click="doSomething()">Third&lt;/hb-btn>
        &lt;hb-btn color="secondary" @click="doSomething()">Second&lt;/hb-btn>
        &lt;hb-btn @click="doSomething()">First&lt;/hb-btn>
    &lt;/template>
&lt;/hb-bottom-action-bar>

&lt;hb-bottom-action-bar close-instead-of-cancel>
    &lt;template v-slot:left-actions>
        &lt;HbBtn icon tooltip="Share" @click="doSomething()" mdi-icon="mdi-user-actions-custom-1" />
    &lt;/template>
    &lt;template v-slot:right-actions>
        &lt;hb-btn color="secondary" @click="doSomething()">Third&lt;/hb-btn>
        &lt;hb-btn color="secondary" @click="doSomething()">Second&lt;/hb-btn>
        &lt;hb-btn @click="doSomething()">First&lt;/hb-btn>
    &lt;/template>
&lt;/hb-bottom-action-bar>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="description"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemBottomActionBar",
        data: function() {
            return {
                success: false,
                description: '',
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'cancel-off', type: 'boolean', default: 'false', description: 'If changed to true, this will turn off the default bottom action bar cancel/close option.' },
                    { name: 'close-instead-of-cancel', type: 'boolean', default: 'true', description: 'If changed to true, this will change the text of the bottom action bar cancel option to say "Close" instead of "Cancel". Functionality is the same.' },
                    { name: 'top-border', type: 'boolean', default: 'true', description: 'If changed to false, this will remove the top border.' },
                    { name: 'border-bottom-radius', type: 'boolean', default: 'false', description: 'If changed to true, this will add 4px of border-radius to bottom left and bottom right side of this component.' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'left-actions', description: 'This slot is for the left side of the bottom action bar, normally where you place a link or a button+icon. Should usually only have 1 action in this slot.' },
                    { name: 'right-actions', description: 'This slot is for setting the primary actions on the right side of the bottom action bar. The spacing/margins are already preset between buttons/elments, so please do not set manual spacing between elements.' }
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@close', description: 'Event for when cancel link, or close link is clicked.' },
                ], 
            };
        },
        methods: {
            handleEmittedClickEvent(e){
                this.success = true;
                this.description = "This " + e + " action is emitted from it's base level custom component.";
            }
        }
    }
</script>

<style scoped>

</style>